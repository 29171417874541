<template>
  <div>
    <b-card>
      <b-card-title title="Documentos en estado de Revisión"></b-card-title>
      <b-card-body>
        <b-row>
          <b-table responsive :fields="fields" :items="items">
            <template #cell(document)="data">
              {{ data.item.document }}
            </template>
            <template #cell(farmCode)="data">
              {{ data.item.farmCode }}
            </template>
            <template #cell(farmName)="data">
              {{ data.item.farmName }}
            </template>
            <template #cell(email)="data">
              {{ data.item.email }}
            </template>
            <template #cell(deadline)="data">
              {{ formatDate(data.item.deadline) }}
            </template>
            <template #cell(uploadDate)="data">
              {{ formatDate(data.item.uploadDate) }}
            </template>
            <template #cell()="data">
              <b-row>
                <b-col col sm="4"
                  ><b-button
                    size="sm"
                    variant="warning"
                    @click="downloadDocumentCalendar(data.item)"
                  >
                    <feather-icon icon="DownloadIcon"/></b-button
                ></b-col>
                <b-col col sm="4"
                  ><b-button
                    variant="primary"
                    size="sm"
                    @click="
                      changeStatusDocumentCalendar(
                        data.item.documentFarmCalendarID,
                        'Aceptado'
                      )
                    "
                    ><feather-icon icon="CheckIcon"/></b-button
                ></b-col>
                <b-col col sm="4"
                  ><b-button
                    variant="danger"
                    size="sm"
                    @click="
                      changeStatusDocumentCalendar(
                        data.item.documentFarmCalendarID,
                        'Rechazado'
                      )
                    "
                    ><feather-icon icon="XIcon"/></b-button
                ></b-col>
              </b-row>
            </template>
          </b-table>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import {
  BLink,
  BButton,
  BImg,
  BSpinner,
  BRow,
  BCol,
  BFormInput,
  BFormTextarea,
  BFormGroup,
  BTable,
  BAlert,
  BBadge,
  BFormFile,
  BForm,
  BFormSelect,
  BFormCheckbox,
  BCard,
  BCardTitle,
  BCardText,
  BCardBody,
  BCalendar,
  BDropdown,
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";
import departamentos from "../../assets/departamentos-municipios.json";
import axios from "@axios";
import axiosIns from "@/libs/axios";
import moment from "moment";

export default {
  components: {
    BAlert,
    BLink,
    BButton,
    BCardText,
    BImg,
    BSpinner,
    BBadge,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BFormFile,
    BTable,
    BFormSelect,
    BFormCheckbox,
    BCardBody,
    BCard,
    BCardTitle,
    BCalendar,
    BDropdown,
  },
  data() {
    return {
      fields: [
        { key: "document", label: "Documento" },
        { key: "farmCode", label: "Código Finca" },
        { key: "farmName", label: "Finca" },
        { key: "email", label: "Email" },
        { key: "deadline", label: "Vencimiento" },
        { key: "uploadDate", label: "Enviado" },
        "Archivo / Aprobar / Rechazar",
      ],
      items: [],
    };
  },
  created() {
    this.getCalendarDocuments();
  },
  methods: {
    getCalendarDocuments() {
      axios
        .get("/CarbonFarm/GetAllCalendarDocumentsByFarm")
        .then((response) => {
          this.items = response.data;
        });
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YY");
    },
    downloadDocumentCalendar(document) {
      window.open(document.urlFile, "_blank");
    },
    changeStatusDocumentCalendar(docCalendarID, statusChanged) {
      axios
        .get("/CarbonFarm/ChangeStatusDocumentFarmCalendar", {
          params: {
            documentFarmCalendarID: docCalendarID,
            statusChanged: statusChanged,
          },
        })
        .then((response) => {
          window.location.reload();
          this.$bvToast.toast("Estado Modificado", {
            title: "Ok",
            variant: "success",
            solid: true,
          });
        });
    },
  },
};
</script>
<style></style>
